<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ mandatorFormTitle }}
  </div>
  <div fxLayout="row" fxLayoutGap="8px">
    <button
      *ngIf="mandatorId && isActive === false"
      mat-raised-button
      color="primary"
      (click)="activateMandator(mandatorId)">
      {{ 'COMMON.ACTIVATE' | translate }}
    </button>
    <button
      *ngIf="mandatorId && isActive === true"
      mat-raised-button
      color="warn"
      (click)="deactivateMandator(mandatorId)">
      {{ 'COMMON.DEACTIVATE' | translate }}
    </button>
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10">
  <mat-card class="content" [class.deactivated]="mandatorId && isActive === false">
    <h2>
      {{ mandatorFormTitle }}
    </h2>
    <mat-card-content>
      <form
        fxLayout="column"
        fxLayoutGap="20px"
        name="addressForm"
        #addressForm="ngForm"
        *ngIf="mandator">
        <div>
          <!--Trade Types-->
          <mat-form-field fxFlex="100">
            <input
              matInput
              placeholder="{{ 'MANDATORS.EDIT.MANDATOR_NAME' | translate }}"
              type="text"
              name="name"
              required
              [(ngModel)]="mandator.Name" />
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox
            id="useExtendedSupportDialog"
            name="useExtendedSupportDialog"
            [(ngModel)]="mandator.UseExtendedSupportDialog"
            >{{ 'MANDATORS.EDIT.USE_EXTENDED_SUPPORT_DIALOG' | translate }}</mat-checkbox
          >
        </div>
        <div>
          <mat-form-field fxFlex="100">
            <input
              matInput
              placeholder="{{
                (mandator.UseExtendedSupportDialog
                  ? 'MANDATORS.EDIT.TECHNICAL_SUPPORT_EMAIL'
                  : 'MANDATORS.EDIT.CUSTOM_SUPPORT_EMAIL'
                ) | translate
              }}"
              type="text"
              name="technicalSupportEmail"
              maxLength="255"
              [(ngModel)]="mandator.TechnicalSupportEmail" />
          </mat-form-field>
        </div>
        <div *ngIf="mandator.UseExtendedSupportDialog">
          <mat-form-field fxFlex="100">
            <input
              matInput
              placeholder="{{ 'MANDATORS.EDIT.PRODUCT_SUPPORT_EMAIL' | translate }}"
              type="text"
              name="productSupportEmail"
              maxLength="255"
              [(ngModel)]="mandator.ProductSupportEmail" />
          </mat-form-field>
        </div>
        <div>
          <!--Trade Types-->
          <mat-form-field fxFlex="100">
            <input
              matInput
              placeholder="{{ 'MANDATORS.EDIT.MANDATOR_SHORT_NAME' | translate }}"
              type="text"
              name="shortName"
              required
              [(ngModel)]="mandator.ShortName" />
          </mat-form-field>
        </div>
        <div>
          <!--Trade Types-->
          <mat-form-field fxFlex="100">
            <mat-label>{{ 'MANDATORS.EDIT.TRADE_TYPE' | translate }}</mat-label>
            <mat-select
              name="tradeTypeMap"
              multiple
              [(ngModel)]="mandator.TradeTypeCodes"
              #tradeTypeMap>
              <mat-option *ngFor="let tradeType of tradeTypes" [value]="tradeType.Code">{{
                tradeType.Name + ' (' + tradeType.Code + ')'
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="100">
            <mat-label>{{ 'MANDATORS.EXECUTING_MANDATOR' | translate }}</mat-label>
            <mat-select name="executingOrganisationUnit" [(ngModel)]="mandator.ExecutingMandatorId">
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <!--Address book-->
          <mat-form-field fxFlex="100">
            <mat-label>{{ 'MANDATORS.ADDRESSBOOK' | translate }}</mat-label>
            <mat-select name="addressbook" [(ngModel)]="mandator.AddressBookId">
              <mat-option *ngFor="let addressBook of addressBooks" [value]="addressBook.Id">{{
                addressBook.Name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MANDATORS.CONFIGURED_ORIGINS' | translate }}"
              type="text"
              name="configuredOrigins"
              required
              [(ngModel)]="mandator.ConfiguredOrigins" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'MANDATORS.CONFIGURED_TRACERS' | translate }}"
              type="text"
              name="configuredTracers"
              [(ngModel)]="mandator.ConfiguredTracers" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <input
              matInput
              placeholder="Datasheet Link Generation Base-URL"
              type="text"
              name="stringGenerationBaseUrl"
              [(ngModel)]="mandator.StringGenerationBaseUrl" />
          </mat-form-field>
        </div>
      </form>
      <ac-mandator-task-settings
        [mandatorId]="mandatorId"
        [mandator]="mandator"
        (settingsChanged)="setTaskSettings($event)"></ac-mandator-task-settings>
    </mat-card-content>
  </mat-card>
</div>
