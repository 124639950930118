import * as OrganisationUnitAction from './organisation-unit.action';
import { tassign } from 'tassign';
import { initOrganisationUnitState, IOrganisationUnitState } from './organisation-unit.state';

export function reducer(
  state: IOrganisationUnitState = initOrganisationUnitState(),
  action: OrganisationUnitAction.Actions
): IOrganisationUnitState {
  if (action instanceof OrganisationUnitAction.GetOrganisationUnitsAction) {
    return tassign(state, {
      organisationUnitsLoading: true,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.GetOrganisationUnitsSuccessAction) {
    return tassign(state, {
      organisationUnits: action.payload,
      organisationUnitsLoading: false,
      organisationUnitsLoaded: true,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.GetOrganisationUnitsFailedAction) {
    return tassign(state, {
      organisationUnits: [],
      organisationUnitsLoading: false,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.GetOrganisationUnitAction) {
    return tassign(state, {
      organisationUnitsLoading: true,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.GetOrganisationUnitSuccessAction) {
    return tassign(state, {
      organisationUnitsLoading: false,
      organisationUnitsLoaded: true,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.GetOrganisationUnitFailedAction) {
    return tassign(state, {
      organisationUnitsLoading: false,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.EditOrganisationUnitAction) {
    return tassign(state, {
      organisationUnitsLoading: true,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.EditOrganisationUnitSuccessAction) {
    return tassign(state, {
      organisationUnitsLoading: false,
      organisationUnitsLoaded: true,
    } as IOrganisationUnitState);
  }

  if (action instanceof OrganisationUnitAction.EditOrganisationUnitFailedAction) {
    return tassign(state, {
      organisationUnitsLoading: false,
      organisationUnitsLoaded: false,
    } as IOrganisationUnitState);
  }

  return state;
}
