<div *ngIf="mandator && mandatorId">
  <h2>{{ 'TASK_SETTINGS.TITLE' | translate }}</h2>
  <mat-tab-group>
    <mat-tab *ngFor="let tradeType of mandator.TradeTypeCodes" [label]="tradeType">
      <div class="margin-top-20">
        <div fxLayout="column" *ngFor="let setting of taskSettings[tradeType]; let i = index">
          <h3>{{ setting.settingTitle | translate }}</h3>
          <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="20">
              <input
                [formControl]="daysOffsetControls[i]"
                required
                matInput
                [min]="1"
                placeholder="{{ 'TASK_SETTINGS.DAYS_OFFSET' | translate }}"
                type="number"
                (blur)="onSettingsChanged()" />
              <mat-error *ngIf="daysOffsetControls[i].getError('min')">{{
                'Min allowed value is 1.'
              }}</mat-error>
              <mat-error *ngIf="daysOffsetControls[i].getError('required')">{{
                'The field is required.'
              }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="20">
              <input
                placeholder="{{ 'TASK_SETTINGS.NOTIFICATION_TIME' | translate }}"
                matInput
                type="time"
                [value]="getString(setting.executionTime)"
                name="datePicker"
                [required]="true" />
            </mat-form-field>
            <mat-form-field fxFlex="60">
              <input
                [(ngModel)]="setting.taskTitle"
                matInput
                [required]="true"
                placeholder="{{ 'TASK_SETTINGS.TASK_TITLE' | translate }}"
                type="text"
                (blur)="onSettingsChanged()" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
