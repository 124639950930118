import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, filter } from 'rxjs/operators';
import { PostPutRoleRequest, RoleService, RoleViewModel } from '../../../shared/apis/advis';
import { ConfigService } from '../../../shared/services/config.service';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../../shared/store';

import * as RoleAction from './role.action';
import {
  AddRoleFailedAction,
  EditRoleFailedAction,
  GetRoleFailedAction,
  GetRolesFailedAction,
  IEditRoleRequest,
} from './role.action';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';

@Injectable()
export class RoleEffects {
  rolesLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.GET_ROLES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.GetRolesAction) => action.payload),
      switchMap(() => {
        return this.roleService.adminRoleGetRoles().pipe(
          map((viewModel: any) => {
            return new RoleAction.GetRolesSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new RoleAction.GetRolesFailedAction(e)))
        );
      })
    )
  );

  roleLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.GET_ROLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.GetRoleAction) => action.payload),
      switchMap((roleId: string) => {
        return this.roleService.adminRoleGetRole(roleId).pipe(
          map((viewModel: RoleViewModel) => {
            return new RoleAction.GetRoleSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new RoleAction.GetRoleFailedAction(e)))
        );
      })
    )
  );

  deleteRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.DELETE_ROLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.DeleteRoleAction) => action.payload),
      filter((roleId: string) => !isNullOrUndefined(roleId)),
      switchMap((roleId: string) => {
        return this.roleService.adminRoleDeleteRole(roleId).pipe(
          map((res: any) => {
            return new RoleAction.DeleteRoleSuccessAction(res);
          }),
          catchError((error: any) => of(new RoleAction.DeleteRoleFailedAction(error)))
        );
      })
    )
  );

  roleAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.ADD_ROLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.AddRoleAction) => action.payload),
      switchMap((addRoleRequest: PostPutRoleRequest) => {
        return this.roleService
          .adminRolePostRole(addRoleRequest)
          .pipe(map((result: RoleViewModel) => new RoleAction.AddRoleSuccessAction(result)));
      }),
      catchError((error: any) => of(new RoleAction.AddRoleFailedAction(error)))
    )
  );

  roleEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.EDIT_ROLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.EditRoleAction) => action.payload),
      switchMap((editRoleRequest: IEditRoleRequest) => {
        return this.roleService
          .adminRolePutRole(editRoleRequest.roleId, editRoleRequest.putRoleRequest)
          .pipe(map((result: RoleViewModel) => new RoleAction.EditRoleSuccessAction(result)));
      }),
      catchError((error: any) => of(new RoleAction.EditRoleFailedAction(error)))
    )
  );

  getAllPermissions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.GET_ALL_PERMISSIONS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: RoleAction.GetAllPermissionsAction) => action.payload),
      switchMap(() => {
        return this.roleService.adminRoleGetAllPermissions().pipe(
          map((permissions: any) => {
            return new RoleAction.GetAllPermissionsSuccessAction(permissions.sort());
          }),
          catchError((e: any) => of(new RoleAction.GetAllPermissionsFailedAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.ADD_ROLE_FAILED, RoleAction.EDIT_ROLE_FAILED),
      map((action: AddRoleFailedAction | EditRoleFailedAction) => {
        if (action instanceof AddRoleFailedAction && action.payload.status === 409) {
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: this.translate.instant('ROLES.ERROR.ROLE_EXISTS'),
            uiElement: UiElementTypeE.DIALOG,
          });
        } else if (action.payload.error === undefined || action.payload.error === null) {
          const message: string = action.type.substr(action.type.indexOf(']') + 1);
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: message,
            uiElement: UiElementTypeE.DIALOG,
          });
        } else {
          return new ErrorAddAction({
            type: ErrorTypeE.SAVE,
            data: action.payload.error.Message,
            uiElement: UiElementTypeE.DIALOG,
          });
        }
      })
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RoleAction.GET_ROLES_FAILED,
        RoleAction.GET_ROLE_FAILED,
        RoleAction.GET_ALL_PERMISSIONS_FAILED,
        RoleAction.DELETE_ROLE_FAILED
      ),
      map(
        (
          action:
            | GetRolesFailedAction
            | GetRoleFailedAction
            | RoleAction.GetAllPermissionsFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: action.payload.error,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private config: ConfigService,
    private translate: TranslateService
  ) {
    // empty
  }
}
