<mat-toolbar class="sub-mat-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-main-nav" fxLayout="row" fxLayoutAlign="center center">
    {{ roleFormTitle }}
  </div>
  <div *ngIf="role">
    <button mat-button color="accent" (click)="onBack()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button color="primary" [disabled]="!isFormValid" (click)="onSave()">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start" class="padding-10 role-edit-form">
  <mat-card fxFlex="50">
    <h2>
      {{ roleFormTitle }}
    </h2>
    <mat-card-content>
      <form fxLayout="column" fxLayoutGap="15px" name="roleForm" #roleForm="ngForm" *ngIf="role">
        <!--Name-->
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <div fxLayout="column" fxFlex.lt-md="50%" fxFlex.gt-sm="50%">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'MANDATORS.NAME' | translate }}"
                type="text"
                maxlength="100"
                name="name"
                required
                [(ngModel)]="role.Name" />
            </mat-form-field>
          </div>
        </div>
        <!--Permissions-->
        <div fxLayout.sm="row" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>{{ 'ROLE.PERMISSIONS' | translate }}</mat-label>
            <mat-select
              name="permissionsMap"
              multiple
              required
              [(ngModel)]="role.Permissions"
              #permissionsMap
              panelClass="permissions-overlay-panel">
              <mat-option *ngFor="let permission of permissions" [value]="permission">{{
                permission
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--Mandators-->
        <div>
          <mat-form-field fxFlex="100%">
            <mat-label>{{ 'COMMON.MANDATORS' | translate }}</mat-label>
            <mat-select
              name="mandatorsMap"
              multiple
              required
              [(ngModel)]="role.MandatorIds"
              #mandatorMap
              panelClass="mandators-overlay-panel">
              <mat-option
                *ngFor="let mandator of mandators"
                [value]="mandator.Id"
                [disabled]="!mandator.IsActive"
                >{{ mandator.Name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
