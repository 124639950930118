import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
// @ts-ignore
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { BlueOfficeCacheManagementService } from './api/blueOfficeCacheManagement.service';
import { DocumentTemplateService } from './api/documentTemplate.service';
import { EmailTemplateService } from './api/emailTemplate.service';
import { EmobilityMasterDataService } from './api/emobilityMasterData.service';
import { ExcelPortService } from './api/excelPort.service';
import { HeatingMasterDataService } from './api/heatingMasterData.service';
import { HubspotService } from './api/hubspot.service';
import { IghConvertService } from './api/ighConvert.service';
import { LeadService } from './api/lead.service';
import { MandatorService } from './api/mandator.service';
import { MasterDataService } from './api/masterData.service';
import { PositionBuilderService } from './api/positionBuilder.service';
import { PositionDependencyRuleService } from './api/positionDependencyRule.service';
import { PositionGroupTemplateService } from './api/positionGroupTemplate.service';
import { ProcessConfigurationService } from './api/processConfiguration.service';
import { ProductlineService } from './api/productline.service';
import { ProjectChecklistTemplateService } from './api/projectChecklistTemplate.service';
import { ProjectReportingService } from './api/projectReporting.service';
import { PvMasterDataService } from './api/pvMasterData.service';
import { RegionalInformationService } from './api/regionalInformation.service';
import { RoleService } from './api/role.service';
import { SearchTableManagementService } from './api/searchTableManagement.service';
import { StandardOperatingProceduresSOPService } from './api/standardOperatingProceduresSOP.service';
import { UserService } from './api/user.service';
import { ValidationRuleService } from './api/validationRule.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    BlueOfficeCacheManagementService,
    DocumentTemplateService,
    EmailTemplateService,
    EmobilityMasterDataService,
    ExcelPortService,
    HeatingMasterDataService,
    HubspotService,
    IghConvertService,
    LeadService,
    MandatorService,
    MasterDataService,
    PositionBuilderService,
    PositionDependencyRuleService,
    PositionGroupTemplateService,
    ProcessConfigurationService,
    ProductlineService,
    ProjectChecklistTemplateService,
    ProjectReportingService,
    PvMasterDataService,
    RegionalInformationService,
    RoleService,
    SearchTableManagementService,
    StandardOperatingProceduresSOPService,
    UserService,
    ValidationRuleService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
