import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as OrganisationUnitAction from './organisation-unit.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { MandatorService, OrganisationUnitViewModel } from '../../shared/apis/advis';
import { ErrorAddAction, ErrorTypeE, UiElementTypeE } from '../../shared/store';

@Injectable()
export class OrganisationUnitEffect {
  organisationUnitsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUnitAction.GET_ORGANISATION_UNITS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: OrganisationUnitAction.GetOrganisationUnitsAction) => action.payload),
      switchMap((mandatorIds: string) => {
        return this.organisationUnitService.organisationUnitGetOrganisationUnits(mandatorIds).pipe(
          map((viewModel: OrganisationUnitViewModel[]) => {
            return new OrganisationUnitAction.GetOrganisationUnitsSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new OrganisationUnitAction.GetOrganisationUnitsFailedAction(e)))
        );
      })
    )
  );

  organisationUnitLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUnitAction.GET_ORGANISATION_UNIT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: OrganisationUnitAction.GetOrganisationUnitAction) => action.payload),
      switchMap(action => {
        return this.organisationUnitService
          .organisationUnitGetOrganisationUnit(action.mandatorId, action.organisationUnitId)
          .pipe(
            map((viewModel: OrganisationUnitViewModel) => {
              return new OrganisationUnitAction.GetOrganisationUnitSuccessAction(viewModel);
            }),
            catchError((e: any) =>
              of(new OrganisationUnitAction.GetOrganisationUnitFailedAction(e))
            )
          );
      })
    )
  );

  organisationUnitAdd$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUnitAction.ADD_ORGANISATION_UNIT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: OrganisationUnitAction.AddOrganisationUnitAction) => action.payload),
      switchMap(
        (editOrganisationUnitRequest: OrganisationUnitAction.IAddOrganisationUnitRequest) => {
          return this.organisationUnitService
            .organisationUnitPostOrganisationUnit(
              editOrganisationUnitRequest.mandatorId,
              editOrganisationUnitRequest.patchOrganisationUnitRequest
            )
            .pipe(
              map((viewModel: OrganisationUnitViewModel) => {
                return new OrganisationUnitAction.AddOrganisationUnitSuccessAction(viewModel);
              }),
              catchError((e: any) =>
                of(new OrganisationUnitAction.AddOrganisationUnitFailedAction(e))
              )
            );
        }
      )
    )
  );

  organisationUnitEdit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUnitAction.EDIT_ORGANISATION_UNIT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: OrganisationUnitAction.EditOrganisationUnitAction) => action.payload),
      switchMap(
        (editOrganisationUnitRequest: OrganisationUnitAction.IEditOrganisationUnitRequest) => {
          return this.organisationUnitService
            .organisationUnitPutOrganisationUnit(
              editOrganisationUnitRequest.mandatorId,
              editOrganisationUnitRequest.organisationUnitId,
              editOrganisationUnitRequest.patchOrganisationUnitRequest
            )
            .pipe(
              map((viewModel: OrganisationUnitViewModel) => {
                return new OrganisationUnitAction.EditOrganisationUnitSuccessAction(viewModel);
              }),
              catchError((e: any) =>
                of(new OrganisationUnitAction.EditOrganisationUnitFailedAction(e))
              )
            );
        }
      )
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganisationUnitAction.ADD_ORGANISATION_UNIT_FAILED,
        OrganisationUnitAction.EDIT_ORGANISATION_UNIT_FAILED
      ),
      map(
        (
          action:
            | OrganisationUnitAction.EditOrganisationUnitFailedAction
            | OrganisationUnitAction.AddOrganisationUnitFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.SAVE,
              data: action.payload.error.Message,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganisationUnitAction.GET_ORGANISATION_UNITS_FAILED,
        OrganisationUnitAction.GET_ORGANISATION_UNIT_FAILED
      ),
      map(
        (
          action:
            | OrganisationUnitAction.GetOrganisationUnitFailedAction
            | OrganisationUnitAction.GetOrganisationUnitsFailedAction
        ) => {
          if (action.payload.error === undefined || action.payload.error === null) {
            const message: string = action.type.substr(action.type.indexOf(']') + 1);
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: message,
              uiElement: UiElementTypeE.DIALOG,
            });
          } else {
            return new ErrorAddAction({
              type: ErrorTypeE.LOAD,
              data: action.payload.error,
              uiElement: UiElementTypeE.DIALOG,
            });
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private organisationUnitService: MandatorService,
    private config: ConfigService
  ) {
    // empty
  }
}
