import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  SharedAddressBookMasterDataViewModel,
  PatchMandatorRequest,
  TradeTypeViewModel,
  MandatorViewModel,
  TaskSettingViewModel,
} from '../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../shared/interfaces/guard-deactivation';
import { getAddressBooks, getMandators, getTradeTypes } from '../../../../shared/store';
import { IRootState } from '../../../../root.state';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import * as UserAction from '../../../state/mandator.action';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import {
  ActivateMandatorAction,
  DeactivateMandatorAction,
  EditMandatorAction,
  GetMandatorAction,
  GetMandatorSuccessAction,
} from '../../../state/mandator.action';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../../../../shared/utils/isNullOrUndefined';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'ac-mandator-edit',
  templateUrl: './mandator-form.component.html',
  styleUrls: ['./mandator-form.component.scss'],
})
export class MandatorFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();
  private readonly singleAddressBookId: number = 10000;

  mandator: PatchMandatorRequest;
  initialMandator: PatchMandatorRequest;

  mandatorId: number;
  mandators: MandatorViewModel[] = [];

  isNew: boolean = false;
  isActive: boolean;

  mandatorFormTitle: string = '';

  tradeTypes: TradeTypeViewModel[] = [];
  selectedTradeTypes: string[] = [];

  addressBooks: SharedAddressBookMasterDataViewModel[] = [
    { Id: this.singleAddressBookId, Name: 'Single' },
  ];

  @ViewChild('addressForm')
  mandatorForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private translate: TranslateService,
    private router: Router,
    private action$: Actions,
    private dialogService: DialogService
  ) {
    // empty
  }

  ngOnInit(): void {
    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;

    this.subscription.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        const urlPath: string = url[1].path.toString();

        if (urlPath === 'edit') {
          if (params && params.id) {
            this.store.dispatch(new GetMandatorAction(params.id));
            this.mandatorId = params.id;
            this.loadMandator();
            this.mandatorFormTitle = this.translate.instant('MANDATORS.EDIT.EDIT_MANDATOR');
            this.isNew = false;
          }
        }
      })
    );

    this.subscription.add(
      this.store.select(getTradeTypes).subscribe((tradeTypes: TradeTypeViewModel[]) => {
        this.tradeTypes = tradeTypes; // .map((x: TradeTypeViewModel) => x.Code);
      })
    );

    this.subscription.add(
      this.store.select(getAddressBooks).subscribe((a: SharedAddressBookMasterDataViewModel[]) => {
        this.addressBooks = [...this.addressBooks, ...a];
      })
    );

    this.subscription.add(
      this.store.select(getMandators).subscribe((mandators: MandatorViewModel[]) => {
        this.mandators = mandators;
      })
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(UserAction.ADD_MANDATOR_SUCCESS, UserAction.EDIT_MANDATOR_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );
  }

  loadMandator(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(UserAction.GET_MANDATOR_SUCCESS), take(1))
        .subscribe((action: GetMandatorSuccessAction) => {
          this.mandator = cloneDeep({
            TradeTypeCodes: action.payload.TradeTypes.map((x: TradeTypeViewModel) => x.Code),
            ConfiguredOrigins: action.payload.ConfiguredOrigins,
            ConfiguredTracers: action.payload.ConfiguredTracers,
            Name: action.payload.Name,
            ShortName: action.payload.ShortName,
            ProductSupportEmail: action.payload.ProductSupportEmail,
            TechnicalSupportEmail: action.payload.TechnicalSupportEmail,
            UseExtendedSupportDialog: action.payload.UseExtendedSupportDialog,
            ExecutingMandatorId: action.payload.ExecutingMandatorId,
            AddressBookId: isNullOrUndefined(action.payload.GlobalAddressBookId)
              ? this.singleAddressBookId
              : action.payload.GlobalAddressBookId,
            TaskSettings: action.payload.TaskSettings,
            StringGenerationBaseUrl: action.payload.StringGenerationBaseUrl,
          }) as PatchMandatorRequest;

          this.initialMandator = cloneDeep(this.mandator);

          this.isActive = action.payload.IsActive;
        })
    );
  }

  isFormValid(): boolean {
    return (
      this.mandatorForm &&
      this.mandatorForm.valid &&
      this.mandator !== undefined &&
      this.taskSettingsValid()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/mandator/mandator/overview']);
  }

  onSave(): void {
    this.initialMandator = cloneDeep(this.mandator);
    const requestMandator: PatchMandatorRequest = cloneDeep(this.mandator);
    if (requestMandator.AddressBookId === this.singleAddressBookId) {
      requestMandator.IsSingleAddressBook = true;
      requestMandator.AddressBookId = undefined;
    }
    this.store.dispatch(
      new EditMandatorAction({
        mandatorId: this.mandatorId,
        patchMandatorRequest: requestMandator,
      })
    );
  }

  deactivateMandator(mandatorId: number): void {
    this.subscription.add(
      this.dialogService
        .openConfirm('MANDATORS.CONFIRM_DEACTIVATE', this.translate.instant('DIALOG.TITLE_CONFIRM'))
        .afterClosed()
        .pipe(filter((confirm: boolean) => !!confirm))
        .subscribe(() => this.store.dispatch(new DeactivateMandatorAction(mandatorId)))
    );
  }

  activateMandator(mandatorId: number): void {
    this.subscription.add(
      this.dialogService
        .openConfirm('MANDATORS.CONFIRM_ACTIVATE', this.translate.instant('DIALOG.TITLE_CONFIRM'))
        .afterClosed()
        .pipe(filter((confirm: boolean) => !!confirm))
        .subscribe(() => this.store.dispatch(new ActivateMandatorAction(mandatorId)))
    );
  }

  setTaskSettings(settings: TaskSettingViewModel[]): void {
    this.mandator.TaskSettings = settings;
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.mandator, this.initialMandator);
  }

  private taskSettingsValid(): boolean {
    return this.mandator.TaskSettings.every(
      ts => ts.DaysOffset && ts.DaysOffset > 0 && ts.NotificationTime && ts.TaskTitle
    );
  }
}
