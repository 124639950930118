import { Action } from '@ngrx/store';
import { PostPutRoleRequest, RoleViewModel } from '../../../shared/apis/advis';

const prefix: string = '[Role]';

export const GET_ROLES: string = `${prefix} Get all roles`;
export const GET_ROLES_SUCCESS: string = `${prefix} Get all roles success`;
export const GET_ROLES_FAILED: string = `${prefix} Get all roles failed`;

export const GET_ROLE: string = `${prefix} Get role`;
export const GET_ROLE_SUCCESS: string = `${prefix} Get role success`;
export const GET_ROLE_FAILED: string = `${prefix} Get role failed`;

export const ADD_ROLE: string = `${prefix} Add role`;
export const ADD_ROLE_SUCCESS: string = `${prefix} Add role success`;
export const ADD_ROLE_FAILED: string = `${prefix} Add role failed`;

export const EDIT_ROLE: string = `${prefix} Edit role`;
export const EDIT_ROLE_SUCCESS: string = `${prefix} Edit role success`;
export const EDIT_ROLE_FAILED: string = `${prefix} Edit role failed`;

export const DELETE_ROLE: string = `${prefix} Delete roles`;
export const DELETE_ROLE_SUCCESS: string = `${prefix} Delete role success`;
export const DELETE_ROLE_FAILED: string = `${prefix} Delete role failed`;

export const GET_ALL_PERMISSIONS: string = `${prefix} Get all permissions`;
export const GET_ALL_PERMISSIONS_SUCCESS: string = `${prefix} Get all permissions success`;
export const GET_ALL_PERMISSIONS_FAILED: string = `${prefix} Get all permissions failed`;

export class GetRolesAction implements Action {
  readonly type: string = GET_ROLES;

  constructor(public payload: any = undefined) {}
}

export class GetRolesSuccessAction implements Action {
  readonly type: string = GET_ROLES_SUCCESS;

  constructor(public payload: RoleViewModel[]) {}
}

export class GetRolesFailedAction implements Action {
  readonly type: string = GET_ROLES_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetRoleAction implements Action {
  readonly type: string = GET_ROLE;

  constructor(public payload: string) {}
}

export class GetRoleSuccessAction implements Action {
  readonly type: string = GET_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class GetRoleFailedAction implements Action {
  readonly type: string = GET_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class AddRoleAction implements Action {
  readonly type: string = ADD_ROLE;

  constructor(public payload: PostPutRoleRequest) {}
}

export class AddRoleSuccessAction implements Action {
  readonly type: string = ADD_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class AddRoleFailedAction implements Action {
  readonly type: string = ADD_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export interface IEditRoleRequest {
  putRoleRequest: PostPutRoleRequest;
  roleId: string;
}

export class EditRoleAction implements Action {
  readonly type: string = EDIT_ROLE;

  constructor(public payload: IEditRoleRequest) {}
}

export class EditRoleSuccessAction implements Action {
  readonly type: string = EDIT_ROLE_SUCCESS;

  constructor(public payload: RoleViewModel) {}
}

export class EditRoleFailedAction implements Action {
  readonly type: string = EDIT_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class DeleteRoleAction implements Action {
  readonly type: string = DELETE_ROLE;

  constructor(public payload: string) {}
}

export class DeleteRoleSuccessAction implements Action {
  readonly type: string = DELETE_ROLE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteRoleFailedAction implements Action {
  readonly type: string = DELETE_ROLE_FAILED;

  constructor(public payload: any = undefined) {}
}

export class GetAllPermissionsAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS;

  constructor(public payload: any = undefined) {}
}

export class GetAllPermissionsSuccessAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class GetAllPermissionsFailedAction implements Action {
  readonly type: string = GET_ALL_PERMISSIONS_FAILED;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | GetRolesAction
  | GetRolesSuccessAction
  | GetRolesFailedAction
  | GetRoleAction
  | GetRoleSuccessAction
  | GetRoleFailedAction
  | AddRoleAction
  | AddRoleSuccessAction
  | AddRoleFailedAction
  | EditRoleAction
  | EditRoleSuccessAction
  | EditRoleFailedAction
  | DeleteRoleAction
  | DeleteRoleSuccessAction
  | DeleteRoleFailedAction
  | GetAllPermissionsAction
  | GetAllPermissionsSuccessAction
  | GetAllPermissionsFailedAction;
