import cloneDeep from 'lodash/cloneDeep';
import { tassign } from 'tassign';
import { MandatorViewModel, MasterDataViewModel, TradeTypeViewModel } from '../apis/advis';
import { IPageSettings } from '../interfaces/Settings';
import {
  ErrorAddAction,
  ErrorClearAction,
  GetGlobalViewSettingsSuccessAction,
  InitStateAction,
  MasterDataLoadFailureAction,
  MasterDataLoadSuccessAction,
  OrganisationUnitsLoadFailureAction,
  OrganisationUnitsLoadSuccessAction,
  SaveGlobalViewSettingsSuccessAction,
  SetNavigationProcessAction,
  SetPageSettingsAction,
  SetTradeTypeCodeFilterToActive,
  SharedActions,
  ToggleSelectMultipleTradetypeCodes,
} from './shared.actions';
import { initSharedState, ISharedState } from './shared.state';

export enum ErrorTypeE {
  UNDEF = 0,
  SAVE,
  LOAD,
  PERMISSION,
  SESSION_TIMEOUT,
}

export enum UiElementTypeE {
  DIALOG = 0,
  ALERT,
  ERRORPAGE,
}

export interface IErrorInfo {
  type: ErrorTypeE;
  uiElement: UiElementTypeE;
  data: string;
  errorPageUrl?: string;
}

export interface IActionSuccess {
  message: string;
  showWithAction?: boolean;
}

export interface IMandatorSelectItem {
  id: string;
  mandatorVm: MandatorViewModel;
  selected: boolean;
}

export interface ITradeTypeSelectItem {
  id: string;
  tradeTypeVm: TradeTypeViewModel;
  selected: boolean;
}

function createMandatorSelectItems(mandators: MandatorViewModel[]): IMandatorSelectItem[] {
  const mandatorSelectItems: IMandatorSelectItem[] = [];

  mandators = mandators.slice().sort((m1: MandatorViewModel, m2: MandatorViewModel) => {
    return m1.Name.localeCompare(m2.Name);
  });

  mandators.forEach((mandator: MandatorViewModel) => {
    const selectItem: IMandatorSelectItem = {
      id: '' + mandator.Id,
      mandatorVm: mandator,
      selected: false,
    };
    mandatorSelectItems.push(selectItem);
  });

  return mandatorSelectItems;
}

function createTradeTypeSelectItems(tradeTypes: TradeTypeViewModel[]): ITradeTypeSelectItem[] {
  const tradeTypeSelectItems: ITradeTypeSelectItem[] = [];

  tradeTypes = tradeTypes.slice().sort((m1: TradeTypeViewModel, m2: TradeTypeViewModel) => {
    return m1.Name.localeCompare(m2.Name);
  });

  tradeTypes.forEach((tradeType: TradeTypeViewModel) => {
    const selectItem: ITradeTypeSelectItem = {
      id: '' + tradeType.Code,
      tradeTypeVm: tradeType,
      selected: false,
    };
    tradeTypeSelectItems.push(selectItem);
  });

  return tradeTypeSelectItems;
}

function buildGlobalFilterSelectItems(
  allMandators: IMandatorSelectItem[],
  selectedMandatorsViewSettings: string[],
  allTradeTypes: ITradeTypeSelectItem[],
  selectedTradeTypesViewSettings: string[]
): { selectableMandators: IMandatorSelectItem[]; selectableTradeTypes: ITradeTypeSelectItem[] } {
  let selectableMandators: IMandatorSelectItem[] = cloneDeep(allMandators);
  selectableMandators.forEach(
    (item: IMandatorSelectItem) => (item.selected = selectedMandatorsViewSettings.includes(item.id))
  );

  // trade types
  let selectableTradeTypes: ITradeTypeSelectItem[] = cloneDeep(allTradeTypes);
  selectableTradeTypes.forEach(
    (item: ITradeTypeSelectItem) =>
      (item.selected = selectedTradeTypesViewSettings.includes(item.id))
  );

  //  HP-2826: Take available trade types from MasterData
  // selectableMandators = GlobalFilterUtil.filterSelectedMandatorsAccordingTradeTypes(selectableMandators, selectableTradeTypes);
  // selectableTradeTypes = GlobalFilterUtil.filterSelectedTradeTypesAccordingMandators(selectableTradeTypes, selectableMandators);

  return { selectableMandators, selectableTradeTypes };
}

export function sharedReducer(
  state: ISharedState = initSharedState(),
  action: SharedActions
): ISharedState {
  if (action instanceof SetNavigationProcessAction) {
    return tassign(state, {
      navigation_processing: action.payload,
    } as ISharedState);
  }
  if (action instanceof InitStateAction) {
    return tassign(state, initSharedState());
  }

  if (action instanceof MasterDataLoadSuccessAction) {
    const masterData: MasterDataViewModel = action.payload;
    const mandators: MandatorViewModel[] = masterData.Mandators;
    const tmpTradeTypeMap: Map<string, TradeTypeViewModel> = new Map<string, TradeTypeViewModel>();
    const tmpTradeTypes: TradeTypeViewModel[] = [];

    for (let mandant of mandators) {
      if (mandant && mandant.TradeTypes) {
        for (let tradeType of mandant.TradeTypes) {
          if (!tmpTradeTypeMap.has(tradeType.Code)) {
            tmpTradeTypeMap.set(tradeType.Code, tradeType);
            tmpTradeTypes.push(tradeType);
          }
        }
      }
    }

    const allMandators: IMandatorSelectItem[] = createMandatorSelectItems(mandators);
    const allTradeType: ITradeTypeSelectItem[] = createTradeTypeSelectItems(tmpTradeTypes);

    return tassign(state, {
      masterDataLoaded: true,
      masterData: action.payload,
      allMandators: allMandators,
      allTradeTypes: allTradeType,
    } as ISharedState);
  }

  if (action instanceof MasterDataLoadSuccessAction) {
    return tassign(state, {
      masterDataLoaded: false,
      masterData: {},
    } as ISharedState);
  }

  if (action instanceof MasterDataLoadFailureAction) {
    return tassign(state, {
      masterDataLoaded: false,
      masterData: {},
    } as ISharedState);
  }

  if (action instanceof OrganisationUnitsLoadSuccessAction) {
    return tassign(state, {
      userMasterDataLoaded: true,
      userMasterData: action.payload,
    } as ISharedState);
  }

  if (action instanceof OrganisationUnitsLoadFailureAction) {
    return tassign(state, {
      userMasterDataLoaded: false,
      userMasterData: {},
    } as ISharedState);
  }

  if (action instanceof GetGlobalViewSettingsSuccessAction) {
    const selectedMandatorsViewSettings: string[] = action.payload.globalVs.clone().mandants;
    const selectedTradeTypesViewSettings: string[] = action.payload.globalVs.clone().tradeTypes;

    const { selectableMandators, selectableTradeTypes } = buildGlobalFilterSelectItems(
      state.allMandators,
      selectedMandatorsViewSettings,
      state.allTradeTypes,
      selectedTradeTypesViewSettings
    );

    return tassign(state, {
      selectedMandatorsViewSettings: action.payload.globalVs.clone().mandants,
      selectedTradeTypesViewSettings: action.payload.globalVs.clone().tradeTypes,
      selectableMandators: selectableMandators,
      selectableTradeTypes: selectableTradeTypes,
    } as ISharedState);
  }

  if (action instanceof SaveGlobalViewSettingsSuccessAction) {
    const selectedMandatorsViewSettings: string[] = action.payload.setting.clone().mandants;
    const selectedTradeTypesViewSettings: string[] = action.payload.setting.clone().tradeTypes;

    const { selectableMandators, selectableTradeTypes } = buildGlobalFilterSelectItems(
      state.allMandators,
      selectedMandatorsViewSettings,
      state.allTradeTypes,
      selectedTradeTypesViewSettings
    );

    return tassign(state, {
      selectedMandatorsViewSettings: selectedMandatorsViewSettings,
      selectedTradeTypesViewSettings: selectedTradeTypesViewSettings,
      selectableMandators: selectableMandators,
      selectableTradeTypes: selectableTradeTypes,
    } as ISharedState);
  }

  if (action instanceof ErrorAddAction) {
    if (action.payload.uiElement !== UiElementTypeE.DIALOG) {
      return null;
    }
    const errorsCopy: any = cloneDeep(state.errors);
    errorsCopy.push(action.payload);
    return tassign(state, {
      errors: errorsCopy,
    });
  }

  if (action instanceof ErrorClearAction) {
    return tassign(state, {
      errors: [],
    });
  }

  if (action instanceof ToggleSelectMultipleTradetypeCodes) {
    return tassign(state, {
      selectMultipleTradeTypeCodes: action.payload,
    });
  }

  if (action instanceof SetTradeTypeCodeFilterToActive) {
    return tassign(state, {
      tradeTypeCodeFilterActive: action.payload,
    });
  }

  if (action instanceof SetPageSettingsAction) {
    const pageSettingsCopy: any = cloneDeep(state.pageSettings);

    let index: number = pageSettingsCopy.findIndex(
      (c: IPageSettings) => c.pageKey === action.payload.pageKey
    );
    if (index >= 0) {
      pageSettingsCopy[index] = action.payload;
    } else {
      pageSettingsCopy.push(action.payload);
    }
    return tassign(state, {
      pageSettings: pageSettingsCopy,
    });
  }

  return state;
}
