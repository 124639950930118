import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TaskSettingViewModel, PatchMandatorRequest } from '../../../shared/apis/advis';
import { TaskSettingsUtil } from '../../../shared/utils/task-settings.util';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { UntypedFormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';

export interface ITaskSetting {
  id: number;
  settingTitle: string;
  taskTitle: string;
  daysOffset: number;
  executionTime: Date;
  mandatorId: number;
  settingType: TaskSettingViewModel.TaskSettingTypeEnum;
}

@Component({
  selector: 'ac-mandator-task-settings',
  templateUrl: './mandator-task-settings.component.html',
  styleUrls: ['./mandator-task-settings.component.sass'],
})
export class MandatorTaskSettingsComponent implements OnChanges {
  private taskSettingTypes: any = TaskSettingViewModel.TaskSettingTypeEnum;

  public daysOffsetControls: UntypedFormControl[] = [];
  public taskSettings: Dictionary<ITaskSetting[]> = {};

  @Input() mandatorId: number;
  @Input() mandator: PatchMandatorRequest;

  @Output() settingsChanged: EventEmitter<TaskSettingViewModel[]> = new EventEmitter<
    TaskSettingViewModel[]
  >();

  ngOnChanges(): void {
    if (!this.mandator || !this.mandatorId) {
      return;
    }

    this.mandator.TradeTypeCodes.forEach(tradeType => {
      const tradeTypeSettings = Object.keys(this.taskSettingTypes).map<ITaskSetting>(
        (settingType: TaskSettingViewModel.TaskSettingTypeEnum) => {
          const existingSetting = this.mandator.TaskSettings.find(
            s => s.TaskSettingType === settingType && s.TradeTypeCode === tradeType
          );
          const defaultSetting = TaskSettingsUtil.getDefaultTaskSettings(settingType, tradeType);
          const daysOffset = existingSetting ? existingSetting.DaysOffset : defaultSetting.days;
          this.daysOffsetControls.push(
            new UntypedFormControl(daysOffset, [Validators.required, Validators.min(1)])
          );
          return {
            id: existingSetting ? existingSetting.Id : undefined,
            daysOffset: daysOffset,
            executionTime: existingSetting
              ? existingSetting.NotificationTime
              : moment().hours(7).minutes(0).toDate(),
            mandatorId: this.mandatorId,
            settingTitle: this.getTitleLocalizationKey(settingType),
            taskTitle: existingSetting ? existingSetting.TaskTitle : defaultSetting.title,
            settingType: settingType,
          };
        }
      );
      this.taskSettings[tradeType] = tradeTypeSettings;
    });
    this.onSettingsChanged();
  }

  public onSettingsChanged(): void {
    let viewModel: TaskSettingViewModel[] = [];
    Object.keys(this.taskSettings).forEach(tradeType => {
      const tradeTypeSettings = this.taskSettings[tradeType];
      const tradeTypeViewModel = tradeTypeSettings.map<TaskSettingViewModel>((ts, i) => ({
        Id: ts.id,
        DaysOffset: this.daysOffsetControls[i].value,
        NotificationTime: moment(ts.executionTime).utc(true).toDate(),
        TaskSettingType: ts.settingType,
        TaskTitle: ts.taskTitle,
        TradeTypeCode: tradeType,
      }));
      viewModel = [...viewModel, ...tradeTypeViewModel];
    });
    this.settingsChanged.emit(viewModel);
  }

  getString(date: Date): string {
    // dirty hack: will be refactoring with https://theia-helion.atlassian.net/browse/HP-6048
    const d = new Date(date);
    return isNullOrUndefined(d) ? '07:00' : d.toTimeString().substring(0, 5);
  }

  private getTitleLocalizationKey(
    taskSettingType: TaskSettingViewModel.TaskSettingTypeEnum
  ): string {
    switch (taskSettingType) {
      case this.taskSettingTypes.UnreachedFirstAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_FIRST_ATTEMPT';
      case this.taskSettingTypes.UnreachedSecondAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_SECOND_ATTEMPT';
      case this.taskSettingTypes.UnreachedThirdAttempt:
        return 'TASK_SETTING_TYPE.UNREACHED_THIRD_ATTEMPT';
      case this.taskSettingTypes.FollowUpBo:
        return 'TASK_SETTING_TYPE.FOLLOWUP_BO';
      case this.taskSettingTypes.FollowUpNbo:
        return 'TASK_SETTING_TYPE.FOLLOWUP_NBO';
      default:
        throw Error('not supported task setting type');
    }
  }
}
