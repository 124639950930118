import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from '../../../../shared/utils/isNullOrUndefined';
import { IRootState } from '../../../../root.state';
import {
  FileLinkViewModel,
  MandatorViewModel,
  PostUserRequest,
  UserCompanyAddressViewModel,
  UserOrganisationUnitViewModel,
  UserViewModel,
} from '../../../../shared/apis/advis';
import { IGuardDeactivation } from '../../../../shared/interfaces/guard-deactivation';
import { getMandators, getOrganisationUnits, getRoles } from '../../../../shared/store';
import * as UserAction from '../../../state/user/user.action';
import {
  AddUserAction,
  EditUserAction,
  GetUserAction,
  GetUserSuccessAction,
} from '../../../state/user/user.action';
import { getUserFilesLoaded } from '../../../state/user/user.selectors';

@Component({
  selector: 'ac-user-edit',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy, IGuardDeactivation {
  private subscription: Subscription = new Subscription();

  user: UserViewModel;
  initialUser: UserViewModel;
  userPassword: string;

  userId: string;

  isNew: boolean = false;

  userFormTitle: string = '';

  pictureUrl: string;
  newPicture: File;
  removePicture: boolean;
  signatureUrl: string;
  newSignature: File;
  removeSignature: boolean;

  organisationUnits: UserOrganisationUnitViewModel[] = [];
  mandators: MandatorViewModel[] = [];
  roles: string[] = [];
  isFormValid: boolean;
  isPictureChange: boolean;

  @ViewChild('addressForm', { static: false })
  addressForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private store: Store<IRootState>,
    private router: Router,
    private action$: Actions
  ) {
    // empty
  }

  ngOnInit(): void {
    const url$: Observable<UrlSegment[]> = this.route.url;
    const params$: Observable<Params> = this.route.params;

    this.subscription.add(
      combineLatest([url$, params$]).subscribe(([url, params]: any) => {
        const urlPath: string = url[1].path.toString();

        if (urlPath === 'add') {
          this.userFormTitle = 'Add user';
          this.isNew = true;
          this.user = {
            SendEmailOnLeadAssigned: false,
            CompanyAddress: {} as UserCompanyAddressViewModel,
          } as UserViewModel;
          this.initialUser = cloneDeep(this.user);
          this.subscribeToUpdateFormStatus();
        } else if (urlPath === 'edit') {
          if (params && params.id) {
            this.store.dispatch(new GetUserAction(params.id));
            this.userId = params.id;
            this.loadUser();
            this.userFormTitle = 'Edit user';
            this.isNew = false;
          }
        }
      })
    );

    this.subscription.add(
      this.store.select(getUserFilesLoaded).subscribe((userFiles: FileLinkViewModel[]) => {
        if (userFiles.length === 0 || isNullOrUndefined(this.user)) {
          return;
        }
        const pictureFile: FileLinkViewModel =
          this.user.PictureFilename &&
          userFiles.find((f: FileLinkViewModel) => f.FileName === this.user.PictureFilename);
        this.pictureUrl = pictureFile && pictureFile.AccessUri;

        const signatureFile: FileLinkViewModel =
          this.user.SignatureFilename &&
          userFiles.find((f: FileLinkViewModel) => f.FileName === this.user.SignatureFilename);
        this.signatureUrl = signatureFile && signatureFile.AccessUri;
      })
    );

    const orgUnits$: Observable<UserOrganisationUnitViewModel[]> =
      this.store.select(getOrganisationUnits);
    const mandators$: Observable<MandatorViewModel[]> = this.store.select(getMandators);
    const roles$: Observable<string[]> = this.store.select(getRoles);

    this.subscription.add(
      combineLatest([orgUnits$, mandators$, roles$]).subscribe(
        ([orgUnits, mandators, roles]: any) => {
          this.organisationUnits = orgUnits;
          this.mandators = mandators;
          this.roles = roles;
        }
      )
    );

    this.subscription.add(
      this.action$
        .pipe(ofType(UserAction.ADD_USER_SUCCESS, UserAction.EDIT_USER_SUCCESS), take(1))
        .subscribe(() => this.onBack())
    );
  }

  loadUser(): void {
    this.subscription.add(
      this.action$
        .pipe(ofType(UserAction.GET_USER_SUCCESS), take(1))
        .subscribe((action: GetUserSuccessAction) => {
          this.user = cloneDeep(action.payload) as UserViewModel;
          this.initialUser = cloneDeep(this.user);
          this.subscribeToUpdateFormStatus();
        })
    );
  }

  subscribeToUpdateFormStatus(): void {
    setTimeout(() => {
      this.subscription.add(
        this.addressForm.statusChanges.subscribe((status: string) => {
          this.isFormValid = status === 'VALID';
        })
      );
    });
  }

  trimUserInput(): void {
    this.user.FirstName = this.user.FirstName?.trim();
    this.user.LastName = this.user.LastName?.trim();
    this.user.PhoneNumber = this.user.PhoneNumber?.trim();
    this.user.CloudId = this.user.CloudId?.trim();
    this.user.Email = this.user.Email?.trim();
    this.user.UserName = this.user.UserName?.trim();
    this.user.JobTitle = this.user.JobTitle?.trim();

    if (this.user.CompanyAddress) {
      this.user.CompanyAddress.CompanyName = this.user.CompanyAddress?.CompanyName?.trim();
      this.user.CompanyAddress.Street = this.user.CompanyAddress?.Street?.trim();
      this.user.CompanyAddress.City = this.user.CompanyAddress?.City?.trim();
      this.user.CompanyAddress.ZipCode = this.user.CompanyAddress?.ZipCode?.trim();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onBack(): void {
    this.router.navigate(['/user/user/overview']);
  }

  onPictureChange(): void {
    this.isPictureChange = true;
  }

  onPictureDelete(value: boolean): void {
    if (!value) {
      this.removePicture = false;
      return;
    }
    this.newPicture = undefined;
    this.pictureUrl = undefined;
    this.removePicture = true;
    this.isPictureChange = true;
  }

  onSignatureDelete(value: boolean): void {
    if (!value) {
      this.removeSignature = false;
      return;
    }
    this.newSignature = undefined;
    this.signatureUrl = undefined;
    this.removeSignature = true;
    this.isPictureChange = true;
  }

  onSave(): void {
    this.trimUserInput();

    this.initialUser = cloneDeep(this.user);
    if (this.isNew) {
      const userData: PostUserRequest = { ...this.user } as PostUserRequest;
      userData.Password = this.userPassword;
      this.store.dispatch(
        new AddUserAction({
          userData: userData,
          files: {
            signature: {
              file: this.newSignature,
              deleted: false,
              filename: isNullOrUndefined(this.newSignature) ? '' : this.newSignature.name,
            },
            Picture: {
              file: this.newPicture,
              deleted: false,
              filename: isNullOrUndefined(this.newPicture) ? '' : this.newPicture.name,
            },
          },
        })
      );
    } else {
      this.store.dispatch(
        new EditUserAction({
          userId: this.userId,
          putUserRequest: this.user as PostUserRequest,
          files: {
            signature: {
              file: this.newSignature,
              deleted: this.removeSignature,
              filename: isNullOrUndefined(this.newSignature) ? '' : this.newSignature.name,
            },
            Picture: {
              file: this.newPicture,
              deleted: this.removePicture,
              filename: isNullOrUndefined(this.newPicture) ? '' : this.newPicture.name,
            },
          },
        })
      );
    }
  }

  get canDeactivateSafely(): boolean {
    return isEqual(this.user, this.initialUser);
  }
}
