/**
 * Helion.Api.Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AdminUserPermissionsViewModel { 
    CanManageUsers?: boolean;
    CanManageOrgUnits?: boolean;
    CanExcelPort?: boolean;
    CanExportData?: boolean;
    CanManageRules?: boolean;
    CanManageProductlines?: boolean;
    CanManageDocumentTemplates?: boolean;
    CanManagePositionGroupTemplates?: boolean;
    CanManageProjectChecklistTemplates?: boolean;
    CanManageRegionalInformation?: boolean;
    CanManagePvMasterData?: boolean;
    CanManageHtMasterData?: boolean;
    CanManageEmMasterData?: boolean;
    CanManageMandators?: boolean;
    CanCreateMandators?: boolean;
    CanManageValidationRules?: boolean;
    CanManageRoles?: boolean;
    CanManageEmailTemplates?: boolean;
    CanClearBlueOfficeCache?: boolean;
    CanImportProjectReportingData?: boolean;
    CanManageProcess?: boolean;
    CanManageSOP?: boolean;
    CanSeeServiceOffering?: boolean;
    CanManageServiceOffering?: boolean;
}
